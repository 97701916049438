import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import PrintIcon from '@material-ui/icons/Print';
import InvoicePrint from '../../helpers/InvoicePrint';
import { checkAddressEnabled } from '../../helpers/functions/addressFieldsEnabled';
import { UserDetailById } from '../../actions/AdminUserAction';
import {
  getCsrfToken,
  getRefundedDailyData,
  TransactionDetailsId,
  refundAuth,
  voidTransaction,
  emailInvoice,
} from '../../actions/PaymentAction';
import { initialAddressState, initialAddressSettings } from '../../helpers/addressSettingsState';
import { getInvoiceByInvoiceNumber } from '../../actions/RequestFormAction';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Transactions.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import validator from '../../helpers/checkValidations';
import { formatCurrency, userRole } from '../../helpers/commonFunction';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import AddressFieldsDropDown from '../../components/AddressFieldsDropDown/AddressFieldsDropDown';

function handleResponseErrors(responseErrors, misMatchError) {
  if (responseErrors.response_code === 1) {
    for (let key in responseErrors.errors) {
      let i = 0;
      misMatchError.push(responseErrors.errors[key][i] + '\n');
    }
  } else {
    misMatchError.push(responseErrors.approval_message);
  }
  return misMatchError;
}

class Viewtransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTransaction: false,
      sideShow: true,
      REFUND: false,
      isLoading: true,
      ORDERID: '',
      AMOUNT: '',
      APPROVALCODE: '',
      DATETIME: new Date(),
      TXNID: '',
      ADDRESS: '',
      TAX: '',
      RESPONSECODE: '',
      RESPONSETEXT: '',
      MASKCARDNUMBER: '',
      surcharge: 0,
      misMatchError: [],
      TransactionData: [],
      refundWithReference: true,
      notes: '',
      creditOrDebit: null,
      CUSTOMERDATA: {},
      achDetails: {},
      paytraceCredentials: {},
      jobId: null,
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      ...initialAddressSettings,
      ...initialAddressState,
      limit: {
        perTransactionLimit: false,
        dailyTransactionLimit: false,
        perRefundLimit: false,
        dailyRefundLimit: false,
      },
      invoiceLabel: 'Invoice',
      UPDATE_VOID_DATE: null,
      refundedFromTxnID: '',
      isRefundedFrom: false,
      isLateFeeApplicable: false,
      lateFee: 0,
      DualFeeEnabled: false,
    };
  }

  componentDidMount() {
    let locationPaydetails =
      JSON.parse(localStorage.getItem('locationPaydetails')) !== null
        ? JSON.parse(localStorage.getItem('locationPaydetails'))
        : '';

    setTimeout(() => {
      const paytraceCredentials = {
        userName: locationPaydetails.paytraceUserName,
        password: locationPaydetails.paytracePassword,
        integratorId: locationPaydetails.paytraceIntegratorId,
        locationId: locationPaydetails._id,
      };
      if (locationPaydetails.customFieldsEnabled) {
        if (locationPaydetails.customFields[0].enabled) {
          if (locationPaydetails.customFields[0].value !== '') {
            this.setState({ invoiceLabel: locationPaydetails.customFields[0].value });
          }
        }
      }
      this.setState({ paytraceCredentials: paytraceCredentials });
      this.getTransactionDetails();
    }, 3000);
    this.getRefundedDailyData();
    this.getCsrfTokens();
    this.fetchAddressInformation();
    const company = JSON.parse(localStorage.getItem('user')).company;
    if (company && company.customFieldsEnabled) {
      const enabledCustomField = company?.customFields?.find((customField) => customField.enabled);
      this.setState({ invoiceLabel: enabledCustomField ? enabledCustomField.value : 'Invoice' });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.invoice && props.invoice !== state.invoiceDetails) {
      return { invoiceDetails: props.invoice?.invoiceInfo?.data?.response };
    }
    return null;
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    });
  }
  getTransactionDetails() {
    let misMatchError = [];

    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.TransactionDetailsId(params, this.state.paytraceCredentials).then((res) => {
      let transaction = res.payload?.paymentData?.data;
      if (transaction?.success) {
        this.setState({ ...transaction.response }, () => {
          this.setState({ isLoading: false });
        });
        //get the order id, fetch invoice, check if customFields are enabled then set order id to the jobId of invoice if invoice has it
        this.props.getInvoiceByInvoiceNumber(transaction.response.ORDERID);
      } else {
        misMatchError.push(transaction?.message || res.payload?.error);
        this.setState({ misMatchError, isLoading: false });
      }
    });
  }
  getRefundedDailyData() {
    let userData = JSON.parse(localStorage.getItem('user'));
    let data = {
      date: new Date(),
      companyId: userData.company ? userData.company._id : '',
      role: userData.role,
      userId: userData._id,
    };
    this.props.getRefundedDailyData(data).then((res) => {
      if (res) {
        this.setState({ TransactionData: res.payload.paymentData.data.response, updatedBy: userData._id });
      }
    });
  }
  handleChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    if (id === 'email') {
      const customerData = this.state.CUSTOMERDATA;
      customerData.email = value;
      this.setState({ CUSTOMERDATA: customerData });
    }
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }

  handleSubmitVoid(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let successMessages = false;
    if (window.confirm('Are you sure you want to void this transaction?')) {
      this.setState({ isLoading: true });

      this.props.voidTransaction(this.state, this.state.csrf, this.state.paytraceCredentials).then((result) => {
        if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
          window.scrollTo(0, 0);
          this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false });
              this.setState({ redirectTransaction: true });
            }, 2000);
          });
        }
        if (result !== undefined && result.type === 'PAYMENT_ERROR') {
          window.scrollTo(0, 0);
          if (
            result.payload.error.data &&
            (result.payload.error.data.statusCode === 403 ||
              result.payload.error.data.statusCode === 401 ||
              result.payload.error.data.statusCode === 409 ||
              result.payload.error.data.statusCode === 500)
          ) {
            let message = result.payload.error.data.message;
            misMatchError.push(message);
            this.setState({ isLoading: false, misMatchError, successMessages });
          } else {
            if (this.props.info.paymentInfo.data.response.length > 0) {
              let responseErrors = JSON.parse(this.props.info.paymentInfo.error.data.response);
              misMatchError = handleResponseErrors(responseErrors, misMatchError);
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
          }
        }
      });
    }
  }
  getAmount(roundSurcharge) {
    if (this.state.baseAmount) {
      return this.state.baseAmount;
    } else if (this.state.AMOUNT) {
      return this.state.AMOUNT - (this.state.SVC_FEE + this.state.TAX + roundSurcharge);
    } else {
      return this.state.amount ?? this.state.baseAmount;
    }
  }
  handleEmailInvoice(_e) {
    let misMatchError = [];
    let successMessages = false;
    const customerData = { ...this.state.CUSTOMERDATA };

    customerData.email = this.state.email;
    const roundSurcharge =
      Math.abs((this.state.surcharge * 1000) % 10) >= 5
        ? (Math.ceil(this.state.surcharge * 100) / 100).toFixed(2)
        : (Math.round(parseFloat(this.state.surcharge) * 100) / 100).toFixed(2);
    const data = {
      svcFee: this.state.SVC_FEE,
      total: this.state.AMOUNT,
      amount: this.getAmount(roundSurcharge),
      customerId: this.state.CUSTOMERDATA._id,
      companyId: this.state.companyId,
      invoiceReceipt: true,
      invoice: this.state.ORDERID,
      email: this.state.CUSTOMERDATA.email || this.state.email,
      tax: this.state.TAX,
      FINAL_STATUS: this.state.FINAL_STATUS,
      TXNID: this.state.TXNID,
      cash: this.state.CASH,
      customerData: customerData,
      locationId: this.state.locationId,
      dualFeeEnabled: this.state.DualFeeEnabled,
      feeSettings: this.state.settings,
      creditOrDebit: this.state?.creditOrDebit,
      surcharge: this.state?.surcharge,
      cardAmount: this.state?.cardAmount,
      achDetails: this.state?.achDetails,
      jobId: this.state?.jobId,
      FRONT_END: this.state.FRONT_END,
      ...(this.state.FRONT_END === 'PAX Terminal' && { digitalSignatureBase64: this.state.digitalSignatureBase64 }),
    };
    if (window.confirm('Are you sure you want to send the invoice  receipt?')) {
      this.setState({ isLoading: true });
      this.props.emailInvoice(data).then((result) => {
        if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
          this.setState({ isLoading: false, successMessages: true, misMatchError });
        }
        if (result !== undefined && result.type === 'PAYMENT_ERROR') {
          this.setState({ isLoading: false, misMatchError, successMessages });
        }
      });
    }
  }

  getInvoiceNumber() {
    let invoiceNumber;
    if (this.state.invoiceDetails && this.state.invoiceDetails.jobId) {
      invoiceNumber = this.state.invoiceDetails.jobId;
    } else if (!this.state.isOneTimeCustomer && this.state.jobId) {
      invoiceNumber = this.state.jobId;
    } else if (!this.state.isOneTimeCustomer && this.state.ORDERID) {
      invoiceNumber = this.state.ORDERID;
    } else if (this.state.isOneTimeCustomer) {
      invoiceNumber = this.state.oneTimeCustomerInvoice;
    }
    return invoiceNumber ?? '';
  }

  handlePrint() {
    let paymentType = this.getPaymentType(this.state);
    let invoiceNBR = this.getInvoiceNumber();
    this.setState({ paymentType: paymentType, invoiceNBR: invoiceNBR }, () => InvoicePrint(this.state));
  }

  checkMaxRefundLimit(val) {
    let maxRefundLimit = this.props.user.permissions.perRefundLimit ? this.props.user.permissions.perRefundLimit : 0;
    let maxRefundDailyLimit = this.props.user.permissions.dailyRefundLimit
      ? this.props.user.permissions.dailyRefundLimit
      : 0;
    let maxDailyRefundedAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT;
    }, 0);
    if (val > maxRefundDailyLimit - maxDailyRefundedAmount && maxDailyRefundedAmount > 0 && maxRefundDailyLimit > 0) {
      this.setState({ limit: { ...this.state.limit, dailyRefundLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, dailyRefundLimit: false } });
        }, 4000);
        return false;
      });
    } else if (val > maxRefundLimit && maxRefundLimit > 0) {
      this.setState({ limit: { ...this.state.limit, perRefundLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, perRefundLimit: false } });
        }, 3000);
        return false;
      });
    } else {
      return true;
    }
  }

  handleSubmitRfundAuth(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let successMessages = false;
    const infoErrorMessage =
      'An error has occurred during the transaction. Please call 877-972-0700 or email techsupport@titaniumpayments.com';
    if (window.confirm('Are you sure you want to refund this transaction?')) {
      if (this.state.status_code === 'S' && this.checkMaxRefundLimit(this.state.AMOUNT)) {
        this.setState({ isLoading: true });
        let props = this.props;
        this.props
          .refundAuth(this.state, this.state.csrf, this.state.paytraceCredentials)
          .then((result) => {
            if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
              window.scrollTo(0, 0);
              this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
                setTimeout(function () {
                  props.history.push('/Transactions');
                }, 2000);
              });
            } else if (result !== undefined && result.type === 'PAYMENT_ERROR') {
              window.scrollTo(0, 0);
              if (
                result.payload.error.data &&
                (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)
              ) {
                let message = result.payload.error.data.message;
                misMatchError.push(message);
                this.setState({ isLoading: false, misMatchError, successMessages });
              }
              if (this.props.info.paymentInfo.data.response.length > 0) {
                let responseErrors = JSON.parse(this.props.info.paymentInfo.error.data.response);
                if (responseErrors.response_code === 1) {
                  for (let key1 in responseErrors.errors) {
                    let i = 0;
                    misMatchError.push(responseErrors.errors[key1][i] + '\n');
                  }
                  this.setState({ isLoading: false, misMatchError, successMessages });
                } else {
                  misMatchError.push(responseErrors.approval_message);
                }
              }
              this.setState({ isLoading: false, misMatchError, successMessages });
            } else {
              misMatchError.push(infoErrorMessage);
              this.setState({
                misMatchError,
                isLoading: false,
                successMessages,
              });
            }
          })
          .catch((_e) => {
            misMatchError.push(infoErrorMessage);
            this.setState({
              misMatchError,
              isLoading: false,
              successMessages,
            });
          });
      }
    } else {
      return;
    }
  }

  getPaymentType = (result) => {
    let type = 'Credit';
    if (result.CARDTYPE === 'ACH' || result.RESPONSECODE === '120' || result?.achDetails?.isOpen) {
      return 'ACH';
    } else {
      if (result.creditOrDebit !== null && typeof result.creditOrDebit === 'string') {
        type = _.capitalize(result.creditOrDebit);
      }
    }

    if (result?.CASH) {
      return 'Cash';
    } else {
      return type;
    }
  };

  fetchAddressInformation() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ id: userData?._id }).then((data) => {
      if (data.type === 'USER_SUCCESS' && data.payload.userData.status === 200) {
        this.setState({
          DualFeeEnabled: data.payload.userData.data.settings?.DualFeeEnabled,
          settings: data.payload.userData.data.settings,
        });
        this.setState({
          addressSettings: {
            ...this.state.addressSettings,
            shippingAddress: data.payload.userData.data.settings?.virtualTerminalSettings?.shippingAddress,
            billingAddress: data.payload.userData.data.settings?.virtualTerminalSettings?.billingAddress,
          },
          isLoading: false,
        });
      }
    });
  }
  handleAddressChanges = () => {
    const shippingAddress = {
      ...this.state.shippingAddress,
    };
    const billingAddress = {
      ...this.state.billingAddress,
    };
    this.setState({ shippingAddress: shippingAddress });
    this.setState({ billingAddress: billingAddress });
  };

  handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      this.setState({ shippingAddress: { ...this.state.billingAddress } });
    }
  };

  getBillingAndShippingAddress = () => {
    if (this.state.FRONT_END === 'Self Payment Link') {
      return (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <AddressFieldsDropDown
              type="Self Payment Link"
              state={this.state}
              handleChange={this.handleAddressChanges}
              handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
              disabled={true}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <>
          {checkAddressEnabled(this.state.addressSettings?.billingAddress) && (
            <Grid item xs={12} sm={12} md={12}>
              <AddressFieldsDropDown
                type="billing"
                state={this.state}
                handleChange={this.handleAddressChanges}
                handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                disabled={true}
              />
            </Grid>
          )}

          {checkAddressEnabled(this.state.addressSettings?.shippingAddress) && (
            <Grid item xs={12} sm={12} md={12}>
              <AddressFieldsDropDown
                type="shipping"
                state={this.state}
                handleChange={this.handleAddressChanges}
                handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                disabled={true}
              />
            </Grid>
          )}
        </>
      );
    }
  };

  render() {
    const maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT;
    }, 0);
    const { t } = this.props;
    const { redirectTransaction } = this.state;
    let invoiceNumber;
    let refund =
      this.state.REFUNDED && this.state.REFUNDED.length > 0
        ? this.state.REFUNDED
        : this.state.REFUND_BY && this.state.ORDERID === ''
        ? [this.state.REFUND_BY]
        : [];

    invoiceNumber = this.getInvoiceNumber();

    const renderPAXTerminalInformation = () => {
      return (
        <>
          {this.state?.pax_serial_number && (
            <Grid item xs={12} sm={3}>
              <Textfield
                id="pax_serial_number"
                type="text"
                labels={t('PAXSerialNumber')}
                value={this.state?.pax_serial_number}
                disabled={true}
                width={true}
              />
            </Grid>
          )}
          {this.state?.tsys_v_number && (
            <Grid item xs={12} sm={3}>
              <Textfield
                id="tsys_v_number"
                type="text"
                labels={t('TSYSVNumber')}
                value={this.state?.tsys_v_number}
                disabled={true}
                width={true}
              />
            </Grid>
          )}
          {this.state?.terminal_id && (
            <Grid item xs={12} sm={2}>
              <Textfield
                id="terminal_id"
                type="text"
                labels={t('TerminalId')}
                value={this.state?.terminal_id}
                disabled={true}
                width={true}
              />
            </Grid>
          )}
        </>
      );
    };

    return (
      <div className="rightPanel">
        {redirectTransaction ? <Redirect to="/Transactions" /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content..">
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
              {this.state.successMessages ? (
                <SuccessMessage successes={[this.props.info.paymentInfo.data.message]} />
              ) : (
                ''
              )}

              <form className={classes.form} noValidate>
                <input type="hidden" name="_csrf" value={this.state.csrf['csrf-token']} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf['csrf-secret']} />
                {this.state.limit.perRefundLimit ? (
                  <h3 className="errorMsg">
                    <span>Warning : </span>Your per refund transaction limit is $
                    {this.props.user.permissions.perRefundLimit ? this.props.user.permissions.perRefundLimit : 0}{' '}
                  </h3>
                ) : (
                  ''
                )}
                {this.state.limit.dailyRefundLimit ? (
                  <h3 className="errorMsg">
                    <span>Warning : </span>Your daily refund transaction limit is $
                    {this.props.user.permissions.dailyRefundLimit ? this.props.user.permissions.dailyRefundLimit : 0}.
                    Make a refund amount of ${this.props.user.permissions.dailyRefundLimit - maxDailyAmount}.
                  </h3>
                ) : (
                  ''
                )}

                {this.state.status_code === 'V' || this.state.status_code === 'v' ? (
                  <h3 className="noteMsg">
                    <span>Note : </span>This transaction has been voided on{' '}
                    {this.state.UPDATE_VOID_DATE
                      ? new Date(this.state.UPDATE_VOID_DATE).toLocaleString()
                      : new Date().toLocaleString()}
                  </h3>
                ) : (
                  ''
                )}
                {this.state.REFUND ? (
                  <h3 className="successMsg">
                    <span>Note : </span>This transaction has been refunded on{' '}
                    {new Date(this.state.REFUND_DATE).toString()}{' '}
                  </h3>
                ) : (
                  ''
                )}
                <Grid container spacing={3} className="ViewTxnFormBox">
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id={t(`${this.state.invoiceLabel}`)}
                      type="text"
                      labels={t(`${this.state.invoiceLabel}`)}
                      value={invoiceNumber ?? ''}
                      disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="Amount"
                      type="text"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      labels={t('Amount')}
                      value={this.state.AMOUNT ? formatCurrency(this.state.AMOUNT).replace('$', '') : '0.00'}
                      disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="Name"
                      type="text"
                      labels={t('Name')}
                      value={
                        this.state.CUSTOMERDATA && this.state.CUSTOMERDATA.firstName
                          ? this.state.CUSTOMERDATA.firstName + ' ' + this.state.CUSTOMERDATA.lastName
                          : 'N/A'
                      }
                      disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="email"
                      type="email"
                      labels={t('Email')}
                      value={this.state.CUSTOMERDATA ? this.state.CUSTOMERDATA.email : this.state.email}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="phone"
                      type="text"
                      labels={t('Phone')}
                      value={this.state.PHONE || this.state.phone}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="APPROVALCODE"
                      type="text"
                      labels={t('ApprovalCode')}
                      value={this.state.APPROVALCODE}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="TXNID"
                      type="text"
                      labels={t('TransactionNumber')}
                      value={this.state.TXNID}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="RESPONSECODE"
                      type="text"
                      labels={t('ResponseCode')}
                      value={this.state.RESPONSECODE}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="MASKCARDNUMBER"
                      type="text"
                      labels={t('MaskNumber')}
                      value={this.state.MASKCARDNUMBER ? this.state.MASKCARDNUMBER : this.state.masked_card_number}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {this.getPaymentType(this.state) === 'ACH' ? (
                    <>
                      <Grid item xs={12}>
                        {' '}
                        <label>
                          <b>Bank Transfer Details</b>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          id="accountName"
                          type="text"
                          labels={t('AccountName')}
                          value={this.state.achDetails.accountName}
                          disabled={true}
                          width={true}
                          onChange={(e) => this.handleChange(e, t)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          id="accountNumber"
                          type="text"
                          labels={t('AccountNumber')}
                          value={this.state.achDetails.accountNumber}
                          disabled={true}
                          width={true}
                          onChange={(e) => this.handleChange(e, t)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          id="routingNumber"
                          type="text"
                          labels={t('RoutingNumber')}
                          value={this.state.achDetails.routingNumber}
                          disabled={true}
                          width={true}
                          onChange={(e) => this.handleChange(e, t)}
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="transactionType"
                      type="text"
                      labels={t('PaymentType')}
                      value={this.getPaymentType(this.state)}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="transactionDate"
                      type="text"
                      labels={t('TransactionDate')}
                      value={new Date(this.state.createdAt).toLocaleString('en-US')}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {this.state.UPDATE_VOID_DATE && (
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        id="updatedVoidDate"
                        type="text"
                        labels={'Updated Void Date'}
                        value={new Date(this.state.UPDATE_VOID_DATE).toLocaleString('en-US')}
                        disabled={true}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                  )}
                  {this.state.isLateFeeApplicable === true && (
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        id="lateFee"
                        type="text"
                        labels="Late Fee"
                        value={this.state.lateFee.toFixed(2)}
                        disabled={true}
                        width={true}
                      />
                    </Grid>
                  )}
                  {this.state.CARDTYPE === 'ACH' &&
                  this.state.SETTLED_DATE &&
                  this.state.achDetails &&
                  this.state.FINAL_STATUS === 'Paid' ? (
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        id="settledDate"
                        type="text"
                        labels={t('Settled Date')}
                        value={new Date(this.state.SETTLED_DATE).toLocaleString('en-US')}
                        disabled={true}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  <Grid item xs={12} sm={4}></Grid>
                  <Grid item xs={12} sm={12}>
                    <Textfield
                      id="RESPONSETEXT"
                      type="text"
                      labels={t('ResponseText')}
                      value={this.state.RESPONSETEXT ? this.state.RESPONSETEXT : ''}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Textfield
                      id="notes"
                      type="text"
                      labels={t('Notes')}
                      value={this.state.notes ? this.state.notes : ''}
                      disabled={true}
                      width={true}
                      multiline={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {this.getBillingAndShippingAddress()}
                  {refund.length > 0 && refund.some((item) => item.TXNID === this.state.TXNID) ? (
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h6" style={{ fontWeight: '400', fontSize: '1.05rem' }}>
                        REFUNDED BY
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {refund.length > 0 && refund.some((item) => item.TXNID === this.state.TXNID)
                    ? refund.map((item, index) => (
                        <div key={index}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                              <Textfield
                                id="Rname"
                                type="text"
                                labels={t('Name')}
                                value={
                                  Object.prototype.hasOwnProperty.call(item, 'REFUND_BY')
                                    ? item.REFUND_BY.firstName + ' ' + item.REFUND_BY.lastName
                                    : item.firstName + ' ' + item.lastName
                                }
                                disabled={true}
                                width={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Textfield
                                id="RtxnId"
                                type="text"
                                labels={t('TransactionId')}
                                value={
                                  Object.prototype.hasOwnProperty.call(item, 'REFUND_TXNID') ? item.REFUND_TXNID : ''
                                }
                                disabled={true}
                                width={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Textfield
                                id="Rusernameame"
                                type="text"
                                labels={t('Username')}
                                value={
                                  Object.prototype.hasOwnProperty.call(item, 'REFUND_BY')
                                    ? item.REFUND_BY.userName
                                    : item.userName
                                }
                                disabled={true}
                                width={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Textfield
                                id="Remail"
                                type="text"
                                labels={t('Email')}
                                value={
                                  Object.prototype.hasOwnProperty.call(item, 'REFUND_BY')
                                    ? item.REFUND_BY.email
                                    : item.email
                                }
                                disabled={true}
                                width={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <Textfield
                                id="Rrole"
                                type="text"
                                labels={t('Role')}
                                value={
                                  Object.prototype.hasOwnProperty.call(item, 'REFUND_BY')
                                    ? userRole(item.REFUND_BY.role)
                                    : userRole(item.role)
                                }
                                disabled={true}
                                width={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <Textfield
                                id="Ramt"
                                type="text"
                                labels={t('Amount')}
                                value={
                                  Object.prototype.hasOwnProperty.call(item, 'REFUND_BY')
                                    ? '$ ' + parseFloat(item.amt).toFixed(2)
                                    : '$ ' + parseFloat(this.state.AMOUNT).toFixed(2)
                                }
                                disabled={true}
                                width={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Textfield
                                id="Rdate"
                                type="text"
                                labels={t('Date')}
                                value={
                                  Object.prototype.hasOwnProperty.call(item, 'REFUND_BY')
                                    ? new Date(item.REFUND_DATE).toLocaleDateString()
                                    : new Date(item.createdAt).toLocaleDateString()
                                }
                                disabled={true}
                                width={true}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      ))
                    : ''}

                  {this.state.isRefundedFrom ? (
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h6" style={{ fontWeight: '400', fontSize: '1.05rem' }}>
                        Referenced Sale
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {this.state.isRefundedFrom ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <Textfield
                          id="ParentTransactionId"
                          type="text"
                          labels={t('Transaction ID')}
                          value={this.state.refundedFromTxnID ? this.state.refundedFromTxnID : ''}
                          disabled={true}
                          width={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Textfield
                          id="ParentAmount"
                          type="text"
                          labels={t('Amount')}
                          value={
                            this.state.parentAmount ? formatCurrency(this.state.parentAmount).replace('$', '') : '0.00'
                          }
                          disabled={true}
                          width={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Textfield
                          id="ParentDate"
                          type="text"
                          labels={t('Date')}
                          value={
                            new Date(this.state.parentDate).toLocaleDateString()
                              ? new Date(this.state.parentDate).toLocaleDateString()
                              : ''
                          }
                          disabled={true}
                          width={true}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {this.state.FRONT_END === 'PAX Terminal' && renderPAXTerminalInformation()}
                  <Grid item xs={12} sm={4}></Grid>
                  <Grid item xs={12} sm={12}></Grid>
                  <Grid className="pb-2">
                    {this.props.history?.location?.state && this.props.history?.location?.state?.parentPage && (
                      <Buttons
                        className={'emailInvoiceTerminal'}
                        variant="contained"
                        color="secondary"
                        text={t('Back')}
                        onClick={this.props.history.goBack}
                      />
                    )}
                    {this.state.FINAL_STATUS === 'Voided' || this.state.FINAL_STATUS === 'Refunded' ? (
                      ''
                    ) : (
                      <Buttons
                        startIcon={<Icon>send</Icon>}
                        className={'emailInvoiceTerminal email-invoice'}
                        variant="contained"
                        text={t('ResendReceipt')}
                        onClick={(e) => this.handleEmailInvoice(e)}
                      />
                    )}

                    {this.state.status_code === 'S' &&
                    !this.state.REFUND &&
                    this.state.REFUNDED &&
                    this.state.REFUNDED?.length === 0 &&
                    this.state.CARDTYPE !== 'ACH' && //remove this once WI10-2830 is done
                    this.props.user.permissions.refunds ? (
                      <Buttons
                        className={'emailInvoiceTerminal'}
                        variant="contained"
                        color="primary"
                        text={t('Refund')}
                        onClick={(e) => this.handleSubmitRfundAuth(e, t)}
                      />
                    ) : (
                      ''
                    )}
                    {(this.state.status_code === 'p' || this.state.status_code === 'P') &&
                    this.props.user.permissions.void ? (
                      <Buttons
                        variant="contained"
                        color="primary"
                        className={'emailInvoiceTerminal'}
                        text={t('Void')}
                        onClick={(e) => this.handleSubmitVoid(e, t)}
                      />
                    ) : (
                      ''
                    )}
                    <Buttons
                      variant="contained"
                      color="primary"
                      className={'emailInvoiceTerminal'}
                      onClick={this.handlePrint.bind(this)}
                      text="Print"
                      startIcon={<PrintIcon style={{ color: 'white' }} className={'printInvoice'} />}
                    />
                  </Grid>
                </Grid>
                {this.state.status_code === 'P' && !this.state.CASH ? (
                  <h3 className="noteMsg">
                    <span>Note : </span>This transaction has not yet settled. If you want to cancel this transaction it
                    can be voided immediately, or refunded after it has settled.
                  </h3>
                ) : (
                  ''
                )}
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
    invoice: state.InvoiceReducer,
  };
}
Viewtransaction.propTypes = {
  getCsrfToken: PropTypes.func,
  match: PropTypes.any,
  TransactionDetailsId: PropTypes.func,
  getRefundedDailyData: PropTypes.func,
  voidTransaction: PropTypes.func,
  info: PropTypes.any,
  emailInvoice: PropTypes.func,
  user: PropTypes.any,
  refundAuth: PropTypes.func,
  history: PropTypes.any,
  t: PropTypes.any,
  invoice: PropTypes.any,
  getInvoiceByInvoiceNumber: PropTypes.func,
  UserDetailById: PropTypes.func,
};
export default connect(mapStateToProps, {
  getCsrfToken,
  getRefundedDailyData,
  emailInvoice,
  refundAuth,
  voidTransaction,
  TransactionDetailsId,
  getInvoiceByInvoiceNumber,
  UserDetailById,
})(withTranslation()(Viewtransaction));
